import {SectionNavContent} from '@/jskit/react/SectionNav';
import {CurrentStatusTabs} from '@/status_pages/display/components/tabs/CurrentStatus/CurrentStatus';
import IncidentsList from '@/status_pages/display/IncidentsList';
import {EmptySection} from '@/status_pages/display/EmptySection';
import {ComponentStatusValue, Incident} from '@/status_pages/types';
import {EmptySectionRocket} from '@/status_pages/display/EmptySectionRocket';

export interface ActiveIncidentsSectionProps {
  currentSectionId: CurrentStatusTabs;
  componentStatusRank: Record<ComponentStatusValue, number>;
  incidents: Incident[];
}

export const ActiveIncidentsSection = ({
  currentSectionId,
  componentStatusRank,
  incidents,
}: ActiveIncidentsSectionProps) => {
  return (
    <SectionNavContent sectionId={CurrentStatusTabs.ACTIVE_INCIDENTS} currentSectionId={currentSectionId}>
      {incidents?.length > 0 ? (
        <IncidentsList componentStatusRank={componentStatusRank} incidents={incidents} />
      ) : (
        <EmptySectionRocket>
          There are no <strong>Active Incidents</strong>
        </EmptySectionRocket>
      )}
    </SectionNavContent>
  );
};
